<template>
<div
    class="add_palette"
    v-if="open" 
    v-on:click.self="cancel">
    <div class="white_box">
        <h3>ユーザ定義パレット</h3>
        <div class="grid_container">
            <span class="grid_1">入力方法</span>
            <div class="grid_2">
                <label v-bind:class="{checked : (select_input == '1')}">
                    <input type="radio" v-model="select_input" value="1"/>
                    リストから選択する
                </label>
                <label v-bind:class="{checked : (select_input == '2')}">
                    <input type="radio" v-model="select_input" value="2"/>
                    入力する
                </label>
            </div>
        </div>
        <!-- リストから選択の場合のみ表示 -->
        <div class="grid_container" v-if="select_input == 1">
            <span class="grid_1">材料を選択</span>
            <div class="grid_2">
                <select v-model="select_zairyo">
                    <option disabled selected value>選択してください</option>
                    <option v-for="item in zairyo_list" v-bind:key="item.id" v-bind:zairyo_list="zairyo_list">{{ item }}</option>
                </select>
                <span class="error_message_in_AddPallete" v-if="!validation_select_zairyo">
                    材料を選択してください
                </span>
            </div>
            <span class="grid_1">熱伝導率</span>
            <div class="grid_2">
                <input type="number" step="0.001" v-model="thermal_conductivity"/>
                <span>W/(m･K)</span>
                <span class="error_message_in_AddPallete" v-if="!validation_thermal_conductivity">
                    正の値の熱伝導率を入力してください
                </span>
            </div>
            <span class="grid_1">色を選択</span>
            <div class="grid_2">
                <label class="select_color color1" v-bind:class="{checked : (select_color == '1')}"><input type="radio" v-model="select_color" value="1"/></label>
                <label class="select_color color2" v-bind:class="{checked : (select_color == '2')}"><input type="radio" v-model="select_color" value="2"/></label>
                <label class="select_color color3" v-bind:class="{checked : (select_color == '3')}"><input type="radio" v-model="select_color" value="3"/></label>
                <label class="select_color color4" v-bind:class="{checked : (select_color == '4')}"><input type="radio" v-model="select_color" value="4"/></label>
                <label class="select_color color5" v-bind:class="{checked : (select_color == '5')}"><input type="radio" v-model="select_color" value="5"/></label>
                <label class="select_color color6" v-bind:class="{checked : (select_color == '6')}"><input type="radio" v-model="select_color" value="6"/></label>
                <label class="select_color color7" v-bind:class="{checked : (select_color == '7')}"><input type="radio" v-model="select_color" value="7"/></label>
                <label class="select_color color8" v-bind:class="{checked : (select_color == '8')}"><input type="radio" v-model="select_color" value="8"/></label>
            </div>
        </div>
        <!-- リストから選択の場合のみ表示 ここまで -->
        <!-- 直接入力の場合の場合のみ表示 -->
        <div class="grid_container" v-if="select_input == 2">
            <span class="grid_1">任意の材料名</span>
            <div class="grid_2">
                <input class="zairyo_name" type="text" v-model="zairyo_name"/>
                <span class="error_message_in_AddPallete" v-if="!validation_zairyo_name">
                    15文字以内の材料名を入力してください
                </span>
            </div>
            <span class="grid_1">層属性</span>
            <div class="grid_2">
                <label v-bind:class="{checked : (select_layer == '1')}">
                    <input type="radio" v-model="select_layer" value="1"/>
                    材料
                </label>
                <label v-bind:class="{checked : (select_layer == '2')}">
                    <input type="radio" v-model="select_layer" value="2"/>
                    空気層
                </label>
            </div>
            <span class="grid_1">熱伝導率</span>
            <div class="grid_2">
                <input type="number" step="0.001" v-model="thermal_conductivity"/>
                <span>W/(m･K)</span>
                <span class="error_message_in_AddPallete" v-if="!validation_thermal_conductivity">
                    正の値の熱伝導率を入力してください
                </span>
            </div>
            <span class="grid_1">色を選択</span>
            <div class="grid_2">
                <label class="select_color color1" v-bind:class="{checked : (select_color == '#003F87') || select_color == '1'}"><input type="radio" v-model="select_color" value="1"/></label>
                <label class="select_color color2" v-bind:class="{checked : (select_color == '#00ACC6') || select_color == '2'}"><input type="radio" v-model="select_color" value="2"/></label>
                <label class="select_color color3" v-bind:class="{checked : (select_color == '#008387') || select_color == '3'}"><input type="radio" v-model="select_color" value="3"/></label>
                <label class="select_color color4" v-bind:class="{checked : (select_color == '#3F6629') || select_color == '4'}"><input type="radio" v-model="select_color" value="4"/></label>
                <label class="select_color color5" v-bind:class="{checked : (select_color == '#3F3F3F') || select_color == '5'}"><input type="radio" v-model="select_color" value="5"/></label>
                <label class="select_color color6" v-bind:class="{checked : (select_color == '#853F87') || select_color == '6'}"><input type="radio" v-model="select_color" value="6"/></label>
                <label class="select_color color7" v-bind:class="{checked : (select_color == '#7B0000') || select_color == '7'}"><input type="radio" v-model="select_color" value="7"/></label>
                <label class="select_color color8" v-bind:class="{checked : (select_color == '#AF3900') || select_color == '8'}"><input type="radio" v-model="select_color" value="8"/></label>
            </div>
        </div>
        <!-- 直接入力の場合の場合のみ表示 ここまで -->
        <div class="flex_center">
             <button v-on:click="cancel">キャンセル</button>
             <button class="btn_big" v-on:click="createPaletteOrError" v-if="!recieved_palette">パレット登録</button>
             <button class="migiake" v-on:click="confirmDeletePalette" v-if="recieved_palette">パレット削除</button>
             <button class="btn_big" v-on:click="updatePaletteOrError" v-if="recieved_palette">パレット更新</button>
        </div>
        <div class="window_container">
            <div class="window_title">
                <span class="window_title_border">参照</span>住宅・建築物の省エネルギー基準及び低炭素建築物の認定基準に関する技術情報
            </div>
            <div class="window_contents">
                入力する熱伝導率は、当該建材等のJISで定める物性値、又は「<a href="https://www.kenken.go.jp/becc/house.html#2-2" target="_blank" rel="noopener noreferrer">設計一次エネルギー消費性能の算定方法</a>」の「第三章暖冷房負荷と外皮性能」「第三節熱貫流率及び線熱貫流率」の「付録A 住宅の平均熱貫流率算出に用いる建材等の熱物性値等」等を参照して下さい。
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'AddPalette',
  data: function() {
    return {
      open: false,
      select_input: '1',
      select_zairyo: "",
      zairyo_name: "初期設定名",
      name: "初期設定名",
      select_layer: '1',
      thermal_conductivity: '0.001',
      select_color: '1',
      recieved_palette: "",
      zairyo_list:[
          //建築研究所資料より。https://www.kenken.go.jp/becc/building.htmlの「建材の一覧 H30.08.10公開」より
          "鋼",
          "アルミニウム",
          "銅",
          "ステンレス鋼",
          "岩石",
          "土壌",
          "コンクリート",
          "軽量コンクリート(軽量 1 種) ",
          "軽量コンクリート(軽量 2 種) ",
          "気泡コンクリート(ALC)",
          "コンクリートブロック(重量) ",
          "コンクリートブロック(軽量) ",
          "セメント・モルタル ",
          "押出成型セメント板",
          "せっこうプラスター",
          "せっこうボード",
          "硬質せっこうボード",
          "しっくい",
          "土壁",
          "ガラス",
          "タイル",
          "れんが",
          "かわら",
          "ロックウール化粧吸音板",
          "火山性ガラス質複合板",
          "ケイ酸カルシウム板 0.8mm ",
          "ケイ酸カルシウム板 1.0mm",
          "天然木材",
          "合板",
          "タタミボード",
          "シージングボード",
          "A 級インシュレーションボード ",
          "パーティクルボード ",
          "木毛セメント板 ",
          "木片セメント板 ",
          "ハードファイバーボード(ハードボード) ",
          "ミディアムデンシティファイバーボード(MDF) ",
          "ビニル系床材",
          "FRP",
          "アスファルト類",
          "畳床",
          "建材畳床(Ⅲ型 50mm 厚) ",
          "建材畳床(K、N 型 50mm 厚) ",
          "カーペット類",
          "グラスウール断熱材 10K 相当",
          "グラスウール断熱材 16K 相当 ",
          "グラスウール断熱材 20K 相当 ",
          "グラスウール断熱材 24K 相当 ",
          "グラスウール断熱材 32K 相当",
          "高性能グラスウール断熱材 16K 相当",
          "高性能グラスウール断熱材 24K 相当 ",
          "高性能グラスウール断熱材 32K 相当",
          "高性能グラスウール断熱材 40K 相当 ",
          "高性能グラスウール断熱材 48K 相当 ",
          "吹込み用グラスウール 13K 相当",
          "吹込み用グラスウール 18K 相当 ",
          "吹込み用グラスウール 30K 相当",
          "吹込み用グラスウール 35K 相当",
          "吹付けロックウール",
          "ロックウール断熱材(マット)",
          "ロックウール断熱材(フェルト) ",
          "ロックウール断熱材(ボード)",
          "吹込み用ロックウール 25K 相当",
          "吹込み用ロックウール 65K 相当",
          "吹込み用セルローズファイバー 25K ",
          "吹込み用セルローズファイバー 45K",
          "吹込み用セルローズファイバー 55K ",
          "押出法ポリスチレンフォーム 保温板 1 種 ",
          "押出法ポリスチレンフォーム 保温板 2 種 ",
          "押出法ポリスチレンフォーム 保温板 3 種",
          "A 種ポリエチレンフォーム 保温板 1 種 2 号 ",
          "A 種ポリエチレンフォーム 保温板 2 種 ",
          "ビーズ法ポリスチレンフォーム 保温板 特号",
          "ビーズ法ポリスチレンフォーム 保温板 1 号 ",
          "ビーズ法ポリスチレンフォーム 保温板 2 号 ",
          "ビーズ法ポリスチレンフォーム 保温板 3 号",
          "ビーズ法ポリスチレンフォーム 保温板 4 号",
          "硬質ウレタンフォーム 保温板 2 種 1 号",
          "硬質ウレタンフォーム 保温板 2 種 2 号 ",
          "吹付け硬質ウレタンフォーム A 種１ ",
          "吹付け硬質ウレタンフォーム A 種 3",
          "フェノールフォーム 保温板 1 種 1 号 ",
          "フェノールフォーム 保温板 1 種 2 号",
          "密閉中空層",
          "非密閉中空層",
      ],
      validation_select_zairyo: true,
      validation_zairyo_name: true,
      validation_thermal_conductivity: true
    };
  },
  props: [],
  mounted(){
        document.addEventListener("keydown", (e) => {
            if (e.keyCode == 27) {
                //escキーを押したとき、キャンセルする
                this.cancel()
            }
        });
    },
  methods: {
    showMenu(palette) {
        //「編集」ボタンから開いた場合は各項目を反映させる
        if(palette){
            this.recieved_palette = palette
            //jpName1が材料リストにある名前と合致する時はドロップダウンリストでの入力を表示
            if(this.zairyo_list.indexOf(palette.jpName1) !== -1){
                this.select_zairyo = palette.jpName1
                this.select_input = 1;
            }else{
               //jpName1が材料リストにある名前と合致しない時はテキストボックスで表示
                this.zairyo_name = palette.jpName1
                this.select_input = 2;
            }
            this.thermal_conductivity = palette.thermal_conductivity
            this.select_color = palette.materialColor
        }
      this.open = true;
    },
    toggleMenu(){
      this.open = !this.open
    },
    hideMenu() {
      this.open = false;
    },
    //表示される名前はリストの名称を使うか、自由入力した文字列を使うか判別
    checkName(){
        if(this.select_input == 1){
            this.name = this.select_zairyo
        }else{
            this.name = this.zairyo_name
        }

        return this.name
    },
    //色を適用
    checkColor(){
        //選択した色を適用する
        switch( this.select_color ) {
            case '1':
                this.select_color = "#003F87"
                break;

            case '2':
                this.select_color = "#00ACC6"
                break;

            case '3':
                this.select_color = "#008387"
                break;

            case '4':
                this.select_color = "#3F6629"
                break;

            case '5':
                this.select_color = "#3F3F3F"
                break;

            case '6':
                this.select_color = "#853F87"
                break;

            case '7':
                this.select_color = "#7B0000"
                break;

            case '8':
                this.select_color = "#AF3900"
                break;
        }
    },
    //入力項目のリセット
    resetForm(){
        this.select_input = '1',
        this.select_zairyo = "",
        this.zairyo_name = "初期設定名",
        this.name = "",
        this.select_layer = '1',
        this.thermal_conductivity = '0.001',
        this.select_color = '1',
        this.recieved_palette = ''
    },
    //バリデーションのリセット
    resetValidation(){
        this.validation_select_zairyo = true;
        this.validation_zairyo_name = true;
        this.validation_thermal_conductivity = true;
    },
    //入力のバリデーション
    isValid(){
        this.resetValidation()
        if(this.select_input == 1){
            if(this.select_zairyo == ""){
                this.validation_select_zairyo = false
            }
            if(this.thermal_conductivity <= 0){
                this.validation_thermal_conductivity = false
            }
            return this.validation_select_zairyo && this.validation_thermal_conductivity
        }else{
            if(this.zairyo_name.length > 15 || this.zairyo_name.length == 0){
                this.validation_zairyo_name = false
            }
            if(this.thermal_conductivity <= 0){
                this.validation_thermal_conductivity = false
            }
            return this.validation_zairyo_name && this.validation_thermal_conductivity
        }
    },
    //パレットを新規作成
    createPalette(){
        this.checkName()
        this.checkColor()
        this.$emit('create-palette', 
            this.name,
            parseFloat(this.thermal_conductivity),
            this.select_layer, 
            this.select_color, 
            )
        this.resetForm()
        this.resetValidation()
        this.hideMenu()
    },
    //パレットを新規作成するかエラーを出すか判別
    createPaletteOrError(){
        if(this.isValid()){
            this.createPalette()
        }
    },
    //パレットを更新
    updatePalette(){
        this.checkName()
        this.checkColor()
        this.$emit('update-palette', 
            this.name,
            this.thermal_conductivity,
            this.select_layer, 
            this.select_color, 
            this.recieved_palette.material,
            this.recieved_palette.ryakusho
            )
        this.resetForm()
        this.resetValidation()
        this.hideMenu()
    },
    //パレットを更新するかエラーを出すか判別
    updatePaletteOrError(){
        if(this.isValid()){
            this.updatePalette()
        }
    },
    //パレットを削除して良いか確認
    confirmDeletePalette(){
        this.$emit('confirm-delete-palette')
    },
    //パレットを削除
    deletePalette(){
        this.$emit('delete-palette',
            this.recieved_palette.material
            )
        this.resetForm()
        this.resetValidation()
        this.hideMenu()
    },
    cancel(){
        //キャンセルボタンをおすとフォームもリセットされる
        this.resetForm()
        this.resetValidation()
        this.hideMenu()
    }
  }
}
</script>
