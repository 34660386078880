<template>
  <div>
    <div class="modal-content" v-if="modalSpinToggle">
        <div class="icon-spin"></div>
        <div class="str-loarding">
            <span class="kei">計</span><span class="san">算</span><span class="chu">中</span>
        </div>
    </div>
  </div>
</template>


<script>
export default {
    data(){
        return {
            
        }
    },
    props: {
        modalSpinToggle: Boolean
    },
    methods: {
        // spinToggle(){
        //     this.modalSpinToggle = !this.modalSpinToggle;
        // },
        // spinHide(){
        //     this.modalSpinToggle = false
        // },
        // spinShow(){
        //     this.modalSpinToggle = true
        // }
    },
    mounted() {
    }
}
</script>

<style scoped>
</style>


