<template>
  <div v-on:click="selectCurrentPalette" v-bind:class="{ selecting_palette: palette == current_palette }">
    <div class="palette_color" >
        <svg x="0" y="0" width="26" height="26">
            <clipPath id="cut-off">
              <circle viewBox="0 0 28 28" class="palette_circle" cx="13" cy="13" r="13">
              </circle>
            </clipPath>
            <circle v-bind:fill="palette.materialColor" 
              v-on:click="selectCurrentPalette"
              viewBox="0 0 28 28" class="palette_circle" cx="13" cy="13" r="13" ></circle>
            <use viewBox="0 0 28 28"
              height="26" 
              x="0" 
              y="0"
              class="pattern"
              fill="rgb(255, 255, 255, 0)"
              stroke-width="2"
              stroke="rgba(0, 0, 0, 0.2)"
              clip-path="url(#cut-off)"
              :xlink:href="palette.pattern">
            </use>
            <text viewBox="0 0 28 28"
              x="14" 
              y="17"
              class="svgtext" 
              font-size="12px" 
              text-anchor="middle"
              fill="rgba(255,255,255,0.4)">{{ palette.ryakusho }}
            </text>
        </svg>
    </div>
    <span class="material_name">{{ palette.jpName1 }}
      <span class="material_detail">{{ palette.jpName2 }}</span>
    </span>
    <!-- 素材名にairが含まれない場合(境界条件でない場合)のみ、その素材が基礎を構成する材料か否か選択できる -->
    <span class="isFoundation" v-if="(palette.material).indexOf('air') < 0">
        <input type="checkbox" v-model="palette.isFoundation"/>
        基礎を構成
    </span>
    <span
      v-if="palette.thermal_conductivity !== ''"
      class="thermal_conductivity">
      {{ palette.thermal_conductivity }}
      <span>W/(m･K)</span>
    </span>
    <span
      v-if="palette.htr_v !== null"
      class="htr_v">
      {{ palette.htr_v }}
      <span>m<sup>2</sup>K/W</span>
    </span>
    <span v-if="checkUserMade" v-on:click.self="editPalette" class="btn_edit">編集</span>
  </div>
</template>

<script>
export default {
  name: 'PaletteItem',
  props: ['palette','current_palette'],
  data(){
        return {
            isUserMade: false,
        }
    },
  computed:{
    //ユーザー定義パレットかどうか判別する
    checkUserMade(){
      
      //paletteのmaterialが"User"から始まる場合ユーザー定義パレットとする
      let materialname = this.palette.material  // eslint-disable-line

      if(this.palette.material.indexOf("User") !== -1){
        this.isUserMade = true;  // eslint-disable-line
      }else{
        this.isUserMade = false;  // eslint-disable-line
      }

      return this.isUserMade
    }
  },
  methods: {
    //このパレットを「選択中のパレット」にする
    selectCurrentPalette(){
      this.$emit('selected', this.palette);
    },
    editPalette(){
      this.$emit('edit-palette', this.palette);
    }
  }
}
</script>
