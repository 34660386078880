<template>
  <div>
    <div class="modal-content"  v-bind:modalErrorToggle="modalErrorToggle" v-if="error_message">
        <p>
            {{error_message}}
        </p>
        <button v-on:click="errorHide">入力画面に戻る</button>
    </div>
  </div>
</template>


<script>
export default {
    data(){
        return {
            
        }
    },
    props: {
        modalErrorToggle: Boolean,
        error_message: String
    },
    methods: {
        // errorToggle(){
        //     this.modalErrorToggle = !this.modalErrorToggle;
        // },
        errorHide(){
            this.$emit('ErrorMsgHide')
        },
        // errorShow(){
        //     this.modalErrorToggle = true
        // }
    },
    mounted() {
    }
}
</script>

<style scoped>
</style>


