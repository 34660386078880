// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import axios from 'axios'

Vue.config.productionTip = false
Vue.prototype.$axios = axios.create({ baseURL: process.env.API_URL_BASE })

// Mixin
import json from '@/mixins/json'
Vue.mixin(json)

import GridCanvas from '@/components/GridCanvas.vue'
import PaletteItem from '@/components/PaletteItem.vue'
import PaletteMenu from '@/components/PaletteMenu.vue'
import Ribbon from '@/components/Ribbon.vue'
import RulerX from '@/components/RulerX.vue'
import RulerY from '@/components/RulerY.vue'
import StatusBar from '@/components/StatusBar.vue'
import AddPalette from '@/components/AddPalette.vue'
import ModalBoxContainer from '@/components/ModalBoxContainer.vue'
import ModalDeleteConfirm from '@/components/ModalDeleteConfirm.vue'
import ModalError from '@/components/ModalError.vue'
import ModalLoadingSpin from '@/components/ModalLoadingSpin.vue'
import Confirm from '@/components/Confirm.vue'


Vue.component('grid-canvas', GridCanvas)
Vue.component('palette-item', PaletteItem)
Vue.component('palette-menu', PaletteMenu)
Vue.component('ribbon', Ribbon)
Vue.component('ruler-x', RulerX)
Vue.component('ruler-y', RulerY)
Vue.component('status-bar', StatusBar)
Vue.component('add-palette', AddPalette)
Vue.component('modal-box-container', ModalBoxContainer)
Vue.component('modal-delete-confirm', ModalDeleteConfirm)
Vue.component('modal-error', ModalError)
Vue.component('modal-loading-spin', ModalLoadingSpin)
Vue.component('confirm', Confirm)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App)
})
