<template>
    <div id="x_ruler_g_container">
        <div v-for="(item,index) in widthList" 
            v-on:click.right.prevent="right_click_menu_x($event,index)"
            v-bind:key="index"
            v-bind:style="{width: (cellWidthArray[index] * zoom_rates[zoom_level] ) + 'px'}"
            class="x_ruler_g"
            height="30px">
            <input v-model.number="widthList[index]" 
                type="text" 
                v-bind:disabled="mode == 'selecting' || mode == 'selected'"
                class="text_ruler_x"
                style="font-size: 11px;"
                v-bind:key="index"
                v-on:focus="xruler_highlight($event,index)"
                v-on:change ="widthChangedFlag()"
                v-if="!is_mitame_view &&(zoom_rates[zoom_level] > 0.55)"
            />
            <div class="space"
                v-if="is_mitame_view || zoom_rates[zoom_level] < 0.55"></div>
        </div>
        <div id="right_click_menu_xruler">
            <div class="click_menu_box">
                <ul>
                    <li v-on:click="addColumn">列を追加</li>
                    <li v-on:click="removeColumn">列を削除</li>
                    <hr>
                    <li v-on:click="makeKabeshinAxis">この左に基礎の心を手動設定する</li>
                    <li v-on:click="removeKabeshinAxis">基礎の心を削除する</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'RulerX',
  data: function(){
      return {
          columnIndex: -1
      };
  },
  props: ['widthList', 'cellWidthList','pos','zoom_level','zoom_rates','mode','is_mitame_view'],
  components: {
  },
  computed:{
    cellWidthArray(){
      if(this.is_mitame_view){
        return this.widthList
      }else{
        return this.cellWidthList
      }
    },
  },
  methods: {
    right_click_menu_x(e,index){
        this.columnIndex = index;
        this.$emit('hidemenu');
        document.getElementById('right_click_menu_xruler').style.left = (index * this.cellWidthList[index] * this.zoom_rates[this.zoom_level]) + 'px';
        document.getElementById('right_click_menu_xruler').style.top =  (e.pageY - 72)+ 'px';
        document.getElementById('right_click_menu_xruler').classList.add('on');
    },
    hideContextMenu() {
        document.getElementById('right_click_menu_xruler').classList.remove('on');
    },
    addColumn(){
        this.$emit('addcolumn', this.columnIndex);
    },
    removeColumn(){
        this.$emit('removecolumn', this.columnIndex);
    },
    xruler_highlight(e,index){
        this.columnIndex = index;
        this.$emit('xruler_highlight', this.columnIndex);
    },
    //基礎の心のx座標を指定する。
    makeKabeshinAxis(){
        this.$emit('make-kabeshin-axis', this.columnIndex);
    },
    //基礎の心のx座標を削除する（土間床上端のy座標を0にする）
    removeKabeshinAxis(){
        this.$emit('make-kabeshin-axis', 0);
    },
    // 列幅の変更をキャッチ
    widthChangedFlag(){
      this.$emit('change-flag');
    },
  }
}
</script>