    <template>
    <div>
        <div v-for="(item,index) in heightList" 
            v-on:click.right.prevent="right_click_menu_y($event,index)"
            v-bind:key="index"
            v-bind:style="{height: (cellHeightArray[index] * zoom_rates[zoom_level] ) + 'px'}"
            width="30px"
            class="y_ruler_g">
            <input 
                v-model.number="heightList[index]" 
                v-bind:disabled="mode == 'selecting' || mode == 'selected'"
                type="text" 
                class="text_ruler_y" 
                style="width:27px; height: 100%; font-size: 11px;"
                v-bind:key="index"
                @focus="yruler_highlight($event,index)"
                v-on:change ="heightChangedFlag()"
                v-if="!is_mitame_view &&(zoom_rates[zoom_level] > 0.55)" />
            <div class="space"
                v-bind:style="{height: (cellHeightList[index] * zoom_rates[zoom_level] / 3) + 'px'}"
                style="width:30px;"
                v-if="is_mitame_view || zoom_rates[zoom_level] < 0.55"></div>
        </div>
        <div id="right_click_menu_yruler">
            <div class="click_menu_box">
                <ul>
                    <li v-on:click="addRow">行を追加</li>
                    <li v-on:click="removeRow">行を削除</li>
                    <hr>
                    <li v-on:click="makeGlAxis">この上にGLを手動設定する</li>
                    <li v-on:click="removeGlAxis">GLを削除する</li>
                    <hr>
                    <li v-on:click="makeDomayukajotanAxis">この上に土間床上端を手動設定する</li>
                    <li v-on:click="removeDomayukajotanAxis">土間床上端を削除する</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'RulerY',
  data: function(){
      return {
          rowIndex: -1
      };
  },
  props: ['heightList', 'cellHeightList','zoom_level','zoom_rates','griddata','mode','is_mitame_view'],
  computed:{
    cellHeightArray(){
      if(this.is_mitame_view){
        return this.heightList
      }else{
        return this.cellHeightList
      }
    },
  },
  methods: {
    right_click_menu_y: function (e, index){
        this.rowIndex = index;
        this.$emit('hidemenu');
        document.getElementById('right_click_menu_yruler').style.left =  25 + 'px';
        document.getElementById('right_click_menu_yruler').style.top =  (index * this.cellHeightList[index] * this.zoom_rates[this.zoom_level]) + 'px';
        document.getElementById('right_click_menu_yruler').classList.add('on');
    },
    hideContextMenu() {
        document.getElementById('right_click_menu_yruler').classList.remove('on');
    },
    addRow(){
        this.$emit('addrow', this.rowIndex);
    },
    removeRow(){
        this.$emit('removerow', this.rowIndex);
    },
    yruler_highlight(e,index){
        this.rowIndex = index;
        this.$emit('yruler_highlight', this.rowIndex);
    },
    //GLのy座標を指定する。
    makeGlAxis(){
        this.$emit('make-gl-axis', this.rowIndex);
    },
    //GLのy座標を削除する（GLのy座標を0にする）
    removeGlAxis(){
        this.$emit('make-gl-axis', 0);
    },
    //土間床上端のy座標を指定する。
    makeDomayukajotanAxis(){
        this.$emit('make-domayukajotan-axis', this.rowIndex);
    },
    //土間床上端のy座標を削除する（土間床上端のy座標を0にする）
    removeDomayukajotanAxis(){
        this.$emit('make-domayukajotan-axis', 0);
    },
    // 列幅の変更をキャッチ
    heightChangedFlag(){
      this.$emit('change-flag');
    },
  }
}
</script>