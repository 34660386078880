<template>
  <div>
    <div class="modal-content confirm"  v-bind:modalConfirmToggle="modalConfirmToggle" v-if="confirm_message">
       <p>{{confirm_message}}</p>
        <!-- 今後ボタンの文字も呼び出し元コンポーネント=app.vueから設定できるようにする -->
        <div class="flex_center">
            <button v-on:click="confirmNo">キャンセル</button>
            <button v-on:click="confirmYes">削除</button>
        </div>
    </div>
  </div>
</template>


<script>
export default {
    data(){
        return {
            
        }
    },
    props: {
        modalConfirmToggle: Boolean,
        confirm_message: String
    },
    methods: {
        confirmNo(){
            this.$emit('confirm-hide')
        },
        confirmYes(){
            this.$emit('confirm-yes')
        }
    },
    mounted() {
    }
}
</script>

<style scoped>
</style>


