<template>
<div class="ribbon">
    <!-- 見た目幅/均等幅の切り替えスイッチ。＃toggle1がcheckedか否かで切り替え -->
    <div class="view-toggle">
        <label class="check" for="toggle1" v-on:click="toggleIsMitameView">
            <span :class="{checked : !is_mitame_view}" class="kinto">均等幅ビュー</span>
            <span :class="{checked : is_mitame_view}" class="mitame">見た目幅ビュー</span>
        </label>
    </div>
    <!-- 拡大縮小ボタン -->
    <div class="zoom_box">
        <div class="zoom-in" v-on:click="zoomIn"></div>
        <div class="zoom-out" v-on:click="zoomOut"></div>
    </div>

    <!-- 選択中のパレットとパレットメニュー -->
    <div class="menu">
        <div class="palette_menu fold_box">
            <label class="palette_menu_icon" 
                v-on:click.stop="togglePaletteMenu">
                選択中のパレット
            </label>
            <div id="current" v-on:click.stop="togglePaletteMenu">
                <palette-item 
                    v-bind:palette="current_palette"
                    v-on:edit-palette="showModalEditPalette" 
                    class="palette">
                </palette-item>
            </div>
            <palette-menu v-bind:palette_list="palette_list" 
                v-on:palette_changed="palette_changed"
                v-on:areaPaint="areaPaint"
                v-on:paletteAdd="paletteAdd"
                v-on:show-modal-add-palette="showModalAddPalette"
                v-on:show-modal-edit-palette="showModalEditPalette"
                v-bind:current_palette="current_palette"
                ref="paletteMenu">
            </palette-menu>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'Ribbon',
  props: ['mouse_point', 'current_palette', 'cellWidthList', 'cellHeightList', 'width', 'height','palette_list','is_mitame_view'],
  methods: {
    zoomIn(){
        this.$emit('zoom_in');
    },
    zoomOut(){
        this.$emit('zoom_out');
    },
    togglePaletteMenu(){
        this.$refs.paletteMenu.toggleMenu();
    },
    palette_changed(palette){
        this.$emit('palette_changed', palette)
    },
    paletteAdd(){
        this.$emit('paletteAdd')
    },
    areaPaint(){
        this.$emit('area_paint')
    },
    showModalAddPalette(){
        this.$emit('show-modal-add-palette')
    },
    showModalEditPalette(palette){
        console.log(`ribbon_${palette}`)  // eslint-disable-line
        this.$emit('show-modal-edit-palette',palette)
    },
    toggleIsMitameView(){
        this.$emit('toggle-mitame-view')
    },
  }
}
</script>
<style scoped>
/*  
リボン(ツールとパレットメニュー）
 */
.ribbon{
	display: flex;
	box-sizing: border-box;
	align-items: center;
	height: 40px;
	margin: 0 0 0 65px;
	padding:4px 15px;
	background-color: #e5e5e6;
}

/* 均等幅・見た目幅の切り替えボタン */
.view-toggle{
	display: inline-flex;
	align-items: center;
	margin-right: 12px;
	cursor: pointer;
}
.view-toggle label{
	position: relative;
	cursor: pointer;
	background-color: #BFBFBF;
	border: 2px #A5A5A6 solid;
	border-radius: 3px;
}
.view-toggle input[type="checkbox"] {
	display: none;
}
.view-toggle .kinto,
.view-toggle .mitame{
	display: inline-block;
	width: 7.5em;
	padding: 4px 2px;
	font-size: 12px;
	text-align: center;
	user-select: none;
}
.view-toggle .kinto.checked,
.view-toggle .mitame.checked {
	font-weight: bolder;
	color: #6B6B6B;
	background-color: white;
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
	box-shadow: 0 2px 1px 1px rgba(0, 0, 0, 0.3);
}
.view-toggle .mitame.checked {
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.line_btn_container{
	display: inline-flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	width: 120px;
	height: 32px;
	color: #6B6B6B;
	background-color: white;
	border-radius: 3px;
}

.line_btn_container:hover{
	box-shadow: 0 2px 1px 1px rgba(0, 0, 0, 0.3);
}
.line_btn_box{
	display: inline-flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	box-sizing: border-box;
	margin-left: 4px;
	width: 50px;
	height: 26px;
	border-top: 2px rgba(0, 0, 0, 0.7) solid;
	border-right: none;
	border-left: 1px rgba(0,0,0,0.7) solid;
	border-bottom: none;
	border-radius: 13px;
	overflow: hidden;
}
.line_label{
	font-size: 12px;
	line-height: 12px;
}
.auto_line,
.manual_line{
	display: inline-block;
	box-sizing: border-box;
	width: 50px;
    height: 26px;
    margin: 0;
	padding: 2px 2px;
	font-size: 14px;
    line-height: 20px;
	color:white;
	background-color: rgba(0,0,0,0.3);
	font-weight: bold;
	white-space: nowrap;
	text-align: center;
	user-select: none;
	cursor:pointer;
	transition: all 0.5s ease;
}
.auto_line{
	background-color: #a14f44;
}
.line_btn_container.checked .line_btn_box .auto_line{
	margin-top: -26px;
}
.manual_line{
	background-color: #2380b6;
}
/* zoomボタン */
.zoom_box{
	display: inline-flex;
	margin-right: 12px;
    padding: 0 12px;
    border-left: 1px solid #aaa;
    border-right: 1px solid #aaa;
}
.zoom-in{
	display: block;
	box-sizing: content-box;
	width: 30px;
	height: 30px;
	margin-right: 12px;
	background-size: auto 223.76471%;
    background-position-x: 30.4%;
    background-position-y: 0;
	background-repeat: no-repeat;
	background-color: transparent;
	border: none;
	position: relative;
	cursor: pointer;
	opacity: 0.8;
}
.zoom-in:hover{
    opacity: 1;
    filter: drop-shadow(0 2px 2px #aaa);
}
.zoom-out{
	display: block;
	content: "";
	width: 30px;
	height: 30px;
    background-size: auto 223.76471%;
	background-position-x: 42%;
    background-position-y: 0;
	background-repeat: no-repeat;
	background-color: transparent;
	border: none;
	position: relative;
	cursor: pointer;
	opacity: 0.8;
}
.zoom-out:hover{
	opacity: 1;
    filter: drop-shadow(0 2px 2px #aaa);
}

/* 統合ボタン */
.btn_tougou{
	width: 70px;
	height: 32px;
	background-color: #555;
    background-size: auto 211.76471%;
    background-position-x: 0;
    background-position-y: 104.54545%;
	background-repeat: no-repeat;
	border-radius: 5px;
	border: none;
	cursor: pointer;
}
.btn_tougou:hover{
	background-color: #3752ad;
	box-shadow: 0 2px 1px 1px rgba(0, 0, 0, 0.3);
}

/* パレットメニュー */

.menu >>> .palette{
	display: inline-flex;
	box-sizing: border-box;
	align-items: center;
	width: 390px;
	max-width: 400px;
	height: 32px;
	margin: 4px 5px 4px 10px;
	padding: 2px 8px;
	background-color: white;
	border-radius: 16px;
	transition:all 0.2s ease-out;
}
.menu >>> .palette:hover{
	background-color: #ffffd9;
	cursor: pointer;
	box-shadow: 0 3px 4px 0 rgba(0,0,0,0.3);
}
.menu >>> .palette.selecting_palette{
	margin: 4px 5px 4px 2px;
	box-shadow: 2px 2px 3px 4px rgba(0,0,0,0.3);
}
.menu >>> .palette.selecting_palette .material_name{
	font-weight: bolder;
}
.menu >>> .palette .palette_color{
	display: block;
	box-sizing: border-box;
	width: 28px;
	min-width: 28px;
	height: 28px;
	min-height: 28x;
	margin: 0 3px 0 -8px;
	padding: 0;
	background-color: transparent;
	border-radius: 50%;
	border: 2px white solid;
}
.menu >>> .palette .material_name{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 20em;
	padding: 0;
	font-size: 16px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
.menu >>> .palette .material_detail{
	font-size: 0.8em;
	color: #333;
	white-space: nowrap;
}
.menu >>> .palette .thermal_conductivity,
.menu >>> .palette .htr_v,
.menu >>> .palette .htr_h{
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
	font-size: 13px;
	line-height: 13px;
	font-weight: bolder;
	margin-left: auto;
	margin-right: 10px;
	white-space: nowrap;
}
.menu >>> .palette .thermal_conductivity span,
.menu >>> .palette .htr_v span,
.menu >>> .palette .htr_h span{
	font-size: 10px;
	line-height: 10px;
	letter-spacing: -0.08em;
	color: #bbb;
}
.menu >>> .palette .isFoundation{ /* ラジオボタン or トグルスイッチ or etc.のUIについては必要に応じて変更してください */
	display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
	font-size: 13px;
	line-height: 13px;
	margin-left: 10px;
	margin-right: 10px;
	white-space: nowrap;
}
.menu >>> .palette .btn_edit{
	padding: 2px 8px;
	font-size: 12px;
	text-align: center;
	white-space: nowrap;
	background-color: #ddd;
	border-radius: 12px;
}
.menu >>> .palette_menu{
	display: inline-flex;
	justify-content: center;
	align-items: center;
	position: relative;
}
.menu >>> .palette_menu_icon{
	display: inline-flex;
	justify-content: center;
	align-items: center;
	height: 32px;
	margin-top: 4px;
	margin-right: 8px;
	font-size: 10px;
	white-space: nowrap;
}
.menu >>> .palette_menu_icon:before{
	content: "";
	display: inline-block;
	width: 32px;
	height: 20px;
	margin-right: 3px;
    background-size: auto 211.76471%;
    background-position-x: 5px;
    background-position-y: 0;
	background-repeat: no-repeat;
	background-color: transparent;
}
.menu >>> .palette_box{
	height: auto;
	max-height: calc(94vh - 160px);
	overflow-x: visible;
	overflow-y: auto;
}
.menu >>> .palette_box::-webkit-scrollbar {
	display: none;
}
.menu >>> .palette_box .c_grey{
	width: calc(100% - 32px);
	margin: 8px auto 0;
	display: flex;
	justify-content: space-between;
}
.menu >>> .palette_box .c_grey span{
	font-size: 12px;
	line-height: 12px;
}
.menu >>> .palette_btn_box{
	display: flex;
	box-sizing: border-box;
	width: 100%;
	margin-bottom: -16px;
	padding: 16px;
	color: rgba(255, 255, 255, 0.5);
	background-color: rgba(0, 0, 0, 0.5);
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
}
.menu >>> .btn_palette_add{
	display: block;
	width: 100%;
	height: 20px;
	border:none;
	border-radius: 10px;
	font-size: 14px;
}

/* パレットメニューの折りたたみ */
.fold_box{
	display: block;
	width: auto;
}
.fold_box label{
	display: inline-flex;
    justify-content: center;
    align-items: center;
	position: relative;
	cursor: pointer;
	transition: all 0.5s;
}
.fold_box label:hover{
	transition: all .10s;
}
.fold_box input{
	display: none;
}
.menu{
	display: inline-flex;
	margin-left: auto;
	margin-right: 20px;
	position: relative;
	z-index: 999;
}
.menu >>> .folded_menu{
    box-sizing: border-box;
    width: 416px;
    max-width: 416px;
    min-width: 416px;
	padding: 16px 8px;
	transition: all .3s ease-out;
}
.menu >>> .folded_menu.folded{
    max-width: 416px;
    min-width: 416px;
	height: 0px;
	padding: 0 8px;
	overflow: hidden;
	border-radius: 5px;
	opacity: 1;
	top: 40px;
	right: 0;
	position: absolute;
	z-index: 900;
}
.menu >>> .folded_menu.expanded{
	display: block;
    max-width: 416px;
    min-width: 416px;
	height: auto;
	max-height: calc(94vh - 80px);
	background: rgba(107, 107, 107, 0.95);
	opacity: 1;
	overflow: scroll;
	position: absolute;
	top: 40px;
	right: 0;
}
.menu >>> .folded_menu.expanded::-webkit-scrollbar {
	display: none;
}
.menu >>> .fold_close{
	display: block;
	width: 100%;
	height: 100%;
	margin-top: -16px;
	margin-bottom: 10px;
	padding:0;
	font-size: 10px;
	text-align: center;
	color: rgba(255, 255, 255, 0.5);
	background-color: rgba(0, 0, 0, 0.5);
	border-bottom-left-radius: 5px;
	border-bottom-left-radius: 5px;
}

.menu >>> .folded_menu.folded>*{
    display: none;
}
</style>
