<template>
  <div v-bind:class="{ folded: !open, expanded: open }" class="folded_menu">
      <span class="fold_close" v-on:click="hideMenu">▲</span>
      <div class="palette_box">
        <div class="c_grey str_12px">
          <span>境界条件</span>
          <span>表面熱伝達抵抗</span>
        </div>
        <PaletteItem 
          v-for="palette in palette_list_kyokai" 
          v-on:selected="selected" 
          v-on:edit-palette="showModalEditPalette" 
          v-bind:key="palette.material" 
          v-bind:palette="palette"
          v-bind:current_palette="current_palette" 
          class="palette">
        </PaletteItem>
        <div class="c_grey str_12px">
          <span>材料条件</span>
          <span>熱伝導率λ</span>
        </div>
        <PaletteItem 
          v-for="palette in palette_list_zairyo" 
          v-on:selected="selected" 
          v-on:edit-palette="showModalEditPalette" 
          v-bind:key="palette.material" 
          v-bind:palette="palette"
          v-bind:current_palette="current_palette" 
          class="palette">
        </PaletteItem>
      </div>
      <div class="palette_btn_box">
          <button class="btn_palette_add" v-on:click.stop="showModalAddPalette">ユーザー定義パレットの作成</button>
      </div>
  </div>
</template>

<script>
import PaletteItem from './PaletteItem'

export default {
  name: 'PaletteMenu',
  data: function() {
    return {
      open: true
    };
  },
  components: {
    PaletteItem
  },
  props: ['palette_list','current_palette'],
  computed :{
    palette_list_kyokai(){
      //materialに'air'を含むパレットを「境界条件パレット」表示用に抜き出す
      let newList = this.palette_list.filter(function(item){
        if ((item.material).indexOf('air') >= 0) return true
      })

      return newList
    },
    palette_list_zairyo(){
      //materialに'air'を含まないパレットを「材料条件パレット」表示用に抜き出す
      let newList = this.palette_list.filter(function(item){
        if ((item.material).indexOf('air') < 0) return true
      })

      return newList
    }
  },
  methods: {
    selected(e){
      this.$emit('palette_changed', e);
      this.$emit('areaPaint', e);
    },
    showModalAddPalette() {
      this.$emit('show-modal-add-palette')
    },
    showModalEditPalette(e) {
      this.$emit('show-modal-edit-palette',e)
    },
    showMenu() {
      this.open = true;
    },
    toggleMenu(){
      this.open = !this.open
    },
    hideMenu() {
      this.open = false;
    },
  }
}
</script>
<style scoped>
</style>