/**
 * JSON関連のメソッド
 */
export default {
    methods: {
        /**
         * コンポーネントで使用される各情報をひとつのオブジェクトにまとめる
         *
         * @param  {String} case_name   ケース名
         * @param {Object[]} paletteList 素材情報
         * @param {Object[]} gridData セル情報
         * @param {Number[]} dxList セルの幅
         * @param {Number[]} dyList セルの高さ
         * @return {Object} まとめたオブジェクト
         */
        mergeData (case_name, paletteList, gridData, dxList, dyList,
                    ground_line_axis, domayukajotan_line_axis,
                    kabeshin_line_axis) {
            // JSON出力用の前処理を行う
            let palettes = this.makePalettes(gridData, paletteList)
            let rows = this.makeRows(gridData, palettes)

            // ひとつのオブジェクトにまとめる
            let mergedData = {
                case_name: case_name,
                palettes: palettes,
                rows: rows,
                dxList: dxList,
                dyList: dyList,
                ground_line_axis: ground_line_axis,
                domayukajotan_line_axis: domayukajotan_line_axis,
                kabeshin_line_axis: kabeshin_line_axis
            }

            return mergedData

        },
        /**
         * 帳票出力用に情報をひとつのオブジェクトにまとめる
         *
         * @param  {String} case_name   ケース名
         * @param {Object[]} paletteList 素材情報
         * @param {Object[]} gridData セル情報
         * @param {Number[]} dxList セルの幅
         * @param {Number[]} dyList セルの高さ
         * @param  {Number} ground_line_axis GLの座標
         * @param  {Number} domayukajotan_line_axis 土間床上端の座標
         * @param  {Number} kabeshin_line_axis 基礎の心の座標
         * @param  {Number} result  計算結果
         * @return {Object} まとめたオブジェクト
         */
        reportData (case_name, paletteList, gridData, dxList, dyList,
                    ground_line_axis, domayukajotan_line_axis,
                    kabeshin_line_axis, result) {
            // JSON出力用の前処理を行う
            let palettes = this.makePalettes(gridData, paletteList)
            let rows = this.makeRows(gridData, palettes)

            // ひとつのオブジェクトにまとめる
            let mergedData = {
                case_name: case_name,
                palettes: palettes,
                rows: rows,
                dxList: dxList,
                dyList: dyList,
                ground_line_axis: ground_line_axis,
                domayukajotan_line_axis: domayukajotan_line_axis,
                kabeshin_line_axis: kabeshin_line_axis,
                result: result
            }

            return mergedData

        },

        /**
         * コンポーネントで使用される素材情報にJSON出力用の前処理を行う
         * 処理後の例: [ {"id": "B2", "key": "indoor_air", "name": "室内", "tc": 1.6, "foundation": false}, ... ]
         *
         * @param {Object[]} gridData コンポーネントで使用されるセル情報
         * @param {Object[]} paletteList コンポーネントで使用される素材情報
         * @return {Object[]} JSON用に変換された素材情報
         */
        makePalettes (gridData, paletteList) {
            // セル内で使用されている素材に限定する
            // materialList: ['#p-outdoor_air', '#p-indoor_air', ...]
            let materiaList = []
            for (let row_ of gridData) {
                for (let cell_ of row_.row) {
                    // cellListにまだ存在しない素材の場合のみ、cellListに追加する
                    if (materiaList.indexOf(cell_.material) === -1) {
                        materiaList.push(cell_.material)
                    }
                }
            }

            // 素材にIDを割り当てる
            let palettes = []
            let palette = null
            let id = ''
            let suffix_no = 1
            for (let material of materiaList) {
                // IDを設定する
                if (material === 'outdoor_air') {
                    // 外気の場合: B1
                    id = 'B1'
                } else if (material === 'indoor_air') {
                    // 室内の場合: B2
                    id = 'B2'
                } else {
                    // それ以外の素材の場合: A1, A2, ...
                    id =  'A' + String(suffix_no)
                    suffix_no += 1
                }
                // 素材オブジェトを取得する
                palette = paletteList.find(palette => palette.material === material)
                // 配列にJSON出力用の素材情報を追加する
                palettes.push({
                    id: id,
                    material: material,
                    name: palette.jpName1 + palette.jpName2,
                    tc: palette.thermal_conductivity,
                    foundation: palette.isFoundation,
                })
            }

            return palettes
        },

        /**
         * コンポーネントで使用されるセル情報にJSON出力用の前処理を行う
         * - 素材IDの行列（二次元配列）に変換する
         * - 処理後の例: [ ["A1", "A1", "A1"], ["B1", "B1", "B2"], ... ]
         * 
         * @param {Object[]} gridData コンポーネントで使用されるセル情報
         * @param {Object[]} palettes JSON出力用の前処理が行われた素材情報
         * @return {Array} JSON出力用の前処理が行われたセル情報
         */
        makeRows (gridData, palettes) {
            let rows = []
            let row = []
            let palette = null
            // セルの行数だけ繰り返す
            for (let row_ of gridData) {
                row = []
                // 行ごとの要素数だけ繰り返す
                for (let cell_ of row_.row) {
                    palette = palettes.find(palette => palette.material === cell_.material)
                    row.push(palette.id)
                }
                rows.push(row)
            }

            return rows
        }
    }
}
