<template>
  <div>
    <div class="status_box">
        <div class="point">
            <p class="xpoint">X: 
                <span id="xpoint_value" v-if="mouse_point.x < 0 || mouse_point.y < 0">-</span>
                <span id="xpoint_value" v-else>{{ mouse_point.x }}</span>
            </p>
            <p class="ypoint">Y: 
                <span id="ypoint_value" v-if="mouse_point.x < 0 || mouse_point.y < 0">-</span>
                <span id="ypoint_value" v-else>{{ mouse_point.y }}</span>
            </p>
        </div>
        <div class="size">
            <span> 幅 :</span>
            <span class="width" v-if="mouse_point.x < 0 || mouse_point.y < 0"> - </span>
            <span class="width" v-else> {{ widthList[mouse_point.x] }} </span>
            <span>mm</span>
            <span>高さ: </span>
            <span class="height" v-if="mouse_point.x < 0 || mouse_point.y < 0"> - </span>
            <span class="height" v-else>{{ heightList[mouse_point.y] }} </span>
            <span>mm</span>
        </div>
        <div class="mouseover_zairyo">
            <span v-if="mouse_point.x < 0 || mouse_point.y < 0">-</span>
            <span v-else>{{griddata[mouse_point.y].row[mouse_point.x].jpName1}}</span>
            <span v-if="mouse_point.x < 0 || mouse_point.y < 0">-</span>
            <span v-else>{{griddata[mouse_point.y].row[mouse_point.x].jpName2}}</span>
        </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'StatusBar',
  data() {
    return {
      
    };
  },
  components: {
    
  },
  props: ['mouse_point','widthList','heightList','griddata'],
  methods: {
  }
}
</script>