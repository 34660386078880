<template>
    <div>
        <div class="confirm" :class="[agree === true ? 'confirm-inactive' : 'confirm-active']">
            <div class="confirm-contents">
                <div class="confirm-title">使用許諾条件</div>
                <div class="confirm-main">
                    <p>下記の使用許諾条件を確認し同意の上、「使用許諾条件に同意する」ボタンをクリックして下さい。</p>
                    <div class="confirm-maintext">
                        <ol>
                            <li>本プログラムの利用者（以下単に「利用者」といいます。）は、本プログラムを無料で使用することができます。ただし、その際の通信料等は利用者が負担するものとします。</li>
                            <li>利用者等が本プログラムの全部又は一部を修正、改変すること、及びリバースエンジニアリング、逆コンパイル又は逆アセンブル等により解析することを禁止します。</li>
                            <li>利用者等が本プログラムの全部又は一部を通信回線等により頒布すること、媒体の如何を問わず複製し第三者に譲渡、販売、貸与、使用許諾することを禁止します。</li>
                            <li>国土交通省住宅局、国土交通省国土技術政策総合研究所（以下「国総研」といいます。）、国立研究開発法人建築研究所（以下「建研」といいます。）及び本ウェブサイトを管理している（一社）日本サステナブル建築協会（以下「プログラム開発元等」と総称します。）は、理由の如何を問わず、利用者に事前の通知等をすることなく、本プログラムの全部又は一部を変更、追加、削除等（本プログラムの提供の一時的な停止を含みます。以下同じ。）することができるものとします。</li>
                            <li>プログラム開発元等は、本プログラムについて、その完全性、正確性、確実性その他いかなる事項に関する保証も行わないものとします。</li>
                            <li>プログラム開発元等は、利用者が本プログラムを使用したことに伴っていかなる損害、損失等が生じたとしても、これらについて一切の保証責任及び賠償責任を負わないものとします。</li>
                            <li>プログラム開発元等は、第４項により本プログラムの全部又は一部が変更、追加、削除等されたことに伴っていかなる損害、損失等が生じたとしても、これらについて一切の保証責任及び賠償責任を負わないものとします。</li>
                            <li>プログラム開発元等は、本プログラムを本サービスで明記する注意制限事項等で特定する以外の機器又はプログラムと組み合わせること、また、第２項に反して本プログラムを改変すること等に起因して生じた損害、損失等について一切の責任を負わないものとします。</li>
                            <li>本プログラムを使用する際に入力する建築材料及び建築設備の仕様等や本プログラムにより出力される計算結果及び評価結果に関する情報は、次の&#9312;から&#9314;までに掲げる目的で、個人や個別の建築物が特定されないようにした上で収集・管理・利用されます。</li>
                                <ol class="circled-number">
                                <li>&#9312; 国土交通省住宅局及び所管行政庁等における建築物の省エネ性能の向上に関する施策の検討</li>
                                <li>&#9313; 国総研及び建研等における建築物の省エネ性能に関する調査、試験、研究及び開発</li>
                                <li>&#9314; 所管行政庁及び建築物のエネルギー消費性能の向上に関する法律（平成27年法律第53号）第15条第１項に規定する登録建築物エネルギー消費性能判定機関等における建築物の省エネ性能に関する審査</li>
                                </ol>
                        </ol>
                    </div>
                </div>
                <div class="confirm-agreement">
                    <a href="https://house.lowenergy.jp/" class="btn_round" style="margin-right:10px">同意しない</a>
                    <div class="agree btn_round" @click="$emit('close')">使用許諾条件に同意する</div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
/**
 * 使用許諾条件の確認と同意取得
 */
export default {
    name:"Confirm",
    props: ["agree"]
}
</script>

<style scoped>
.back-inactive {
    visibility: hidden;
    opacity: 0;
    z-index: -1;
}
.confirm{
  width: 80%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 10px 10px;
  background-color: white;
  border-radius: 7px;
  transition:all 1.0s;
}
.confirm-active {
    visibility: visible;
    opacity: 1;
    z-index: 9999;
}
.confirm-inactive {
    visibility: hidden;
    opacity: 0;
    z-index: -1;
}
.confirm-contents{
    height: 98%;
    margin: 10px 10px;
    padding: 0 10px;
    font-size: 16px;
}
.confirm-contents > .confirm-title{
    height: 10%;
    font-size: 22px;
    letter-spacing: 2px;
    border-bottom: 1px #ccc solid;
}
.confirm-contents > .confirm-main{
    height: 70%;
}
.confirm-main > .confirm-maintext{
    height: calc(100vh - 260px);
    margin-top: 0;
    margin-bottom: 16px;
    overflow-y: scroll;
    background-color: white;
    border: 1px #ccc solid;
}
.confirm-contents > .confirm-agreement{
    height: 10%;
}
.confirm-agreement a{
    display: inline-block;
    text-decoration: none;
    margin-left: calc(calc(100% / 2) - calc(3.2em + 8em));
    padding: 0.8em 1.6em;
    color: white;
    background-color: #aaa;
}
.confirm-agreement > .agree{
    display: inline-block;
    padding: 0.8em 1.6em;
    color: white;
    background-color: #2380b6;
    cursor: pointer;
}
.circled-number{
    list-style: none;
}
.btn_round{
    border-radius: 5px;
}
</style>


