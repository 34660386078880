<template>
  <div>
    <div class="modal-dialog" v-if="modalToggle">
        <modal-loading-spin v-bind:modalSpinToggle="modalSpinToggle"></modal-loading-spin>
        <modal-delete-confirm v-on:confirm-hide="hide" v-on:confirm-yes="confirmYes" v-bind:confirm_message="confirm_message"></modal-delete-confirm>
        <modal-error v-on:ErrorMsgHide="hide" v-bind:error_message="error_message"></modal-error>
        <slot></slot>
    </div>
  </div>
</template>


<script>
export default {
    data(){
        return {
            modalToggle: false,
            modalSpinToggle: false,
            modalConfirmToggle: false,
            modalErrorToggle: false
        }
    },
    props: {
        error_message: String,
        confirm_message: String
    },
    methods: {
        toggle(){
            this.modalToggle = !this.modalToggle;
        },
        hide(){
            this.modalToggle = false
        },
        show(){
            this.modalToggle = true
        },
        LoadingSpinToggle(){
            this.modalSpinToggle = !this.modalSpinToggle
        },
        LoadingSpinShow(){
            this.modalSpinToggle = true
        },
        LoadingSpinHide(){
            this.modalSpinToggle = false
            this.modalToggle = false
        },
        ConfirmToggle(){
            this.modalConfirmToggle = !this.modalConfirmToggle
        },
        ConfirmShow(){
            this.modalToggle = true
            this.modalConfirmToggle = true
        },
        ConfirmHide(){
            this.modalConfirmToggle = false
            this.modalToggle = false
        },
        confirmYes(){
            this.$emit('confirm-yes')
        },
        confirmNo(){
            this.ConfirmHide()
        },
        ErrorMsgToggle(){
            this.modalErrorToggle = !this.modalErrorToggle
        },
        ErrorMsgShow(){
            this.modalToggle = true
            this.modalErrorToggle = true
        },
        ErrorMsgHide(){
            this.modalErrorToggle = false;
        },
    },
    mounted() {
    }
}
</script>

<style scoped>

 
</style>


