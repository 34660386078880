<template>
<div id="canvas_parent">
		<table id="canvas_child" >
			<thead>
				<tr>
					<th id="x_ruler_header"></th>
					<th id="x_ruler">
              <!-- X方向のルーラー -->
              <ruler-x 
                v-bind:widthList="widthList" 
                v-bind:cellWidthList="cellWidthList"
                v-bind:zoom_level="zoom_level"
                v-bind:zoom_rates="zoom_rates"
                v-bind:pos="pos"
                v-bind:mode="mode"
                v-bind:is_mitame_view="is_mitame_view"
                ref="rulerX" 
                v-on:hidemenu="hideMenu" 
                v-on:addcolumn="addColumn" 
                v-on:removecolumn="removeColumn"
                v-on:xruler_highlight="xrulerHighlight"
                v-on:make-kabeshin-axis="makeKabeshinAxis"
                v-on:change-flag="changedFlag"
                >
                </ruler-x>
          </th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<th id="y_ruler">
              <!-- Y方向のルーラー -->
              <ruler-y
                v-bind:heightList="heightList" 
                v-bind:cellHeightList="cellHeightList"
                v-bind:zoom_level="zoom_level"
                v-bind:zoom_rates="zoom_rates"
                v-bind:griddata="griddata"
                v-bind:mode="mode"
                v-bind:is_mitame_view="is_mitame_view"
                ref="rulerY" 
                v-on:hidemenu="hideMenu"
                v-on:addrow="addRow" 
                v-on:removerow="removeRow"
                v-on:yruler_highlight="yrulerHighlight"
                v-on:make-gl-axis="makeGlAxis"
                v-on:make-domayukajotan-axis="makeDomayukajotanAxis"
                v-on:change-flag="changedFlag"
                ></ruler-y>
          </th>
					<td id="cells">
              <svg id="cells_svg"
                  v-bind:width="svg_width * zoom_rates[zoom_level]" 
                  v-bind:height="svg_height * zoom_rates[zoom_level]"
                  v-on:mousedown="axisOnMousedown"
                  v-on:mouseup="axisOnMouseup"
                  v-on:mousemove="axisOnMousemove"
                  v-on:mouseleave="axisOnMouseleave"
                  >
                  <g v-for="(col,y) in griddata" v-bind:key="y" id="rows">
                      <g v-for="(cell,x) in col.row" v-bind:key="x"
                          v-on:mouseover="mouseover" 
                          class="cell">
                          <rect v-bind:width="cellWidthArray[x] * zoom_rates[zoom_level]" 
                              :height="cellHeightArray[y] * zoom_rates[zoom_level]" 
                              :x="cellXArray[x] * zoom_rates[zoom_level]" 
                              :y="cellYArray[y] * zoom_rates[zoom_level]"
                              :fill="cell.materialColor"
                              stroke="rgba(0, 0, 0, 0.3)">
                          </rect>
                          <use
                              v-if="!is_mitame_view"
                              v-bind:width="cellWidthArray[x] * zoom_rates[zoom_level]" 
                              :height="cellHeightArray[y] * zoom_rates[zoom_level]" 
                              :x="cellXArray[x] * zoom_rates[zoom_level]" 
                              :y="cellYArray[y] * zoom_rates[zoom_level]"
                              :xlink:href="cell.pattern"
                              class="pattern"
                              fill="rgb(255, 255, 255, 0)"
                              stroke-width="2"
                              stroke="rgba(0, 0, 0, 0.2)">
                          </use>
                          <text v-bind:x="cellXArray[x] * zoom_rates[zoom_level] +(cellWidthArray[x] * zoom_rates[zoom_level]/ 2)" 
                              :y="cellYArray[y] * zoom_rates[zoom_level] + 28"
                              class="svgtext" 
                              :font-size="(cellDefaultSize * zoom_rates[zoom_level] / 2.5)" 
                              text-anchor="middle" 
                              fill="rgba(255,255,255,0.4)">{{ cell.ryakusho }}
                          </text>
                      </g>
                  </g>
                  <!--選択範囲 -->
                  <g class="selected_area" v-if="!is_mitame_view && (mode=='selected'||'selecting')">
                    <rect 
                        v-bind:x="posPaintStart.x * cellDefaultSize * zoom_rates[zoom_level]" 
                        :y="posPaintStart.y * cellDefaultSize * zoom_rates[zoom_level]" 
                        :width="(select_end_x + 1) * zoom_rates[zoom_level]" 
                        :height="(select_end_y + 1)* zoom_rates[zoom_level]"
                        fill="rgba(255, 255, 255, 0.2)"
                        :stroke="selected_area_line_color"
                        stroke-width="3px"></rect>
                  </g>
                  <!--「塗る」ボタン -->
                  <g class="paint_button"
                    v-if="!is_mitame_view && (mode=='selected')"
                    v-on:mousedown="areaPaint(current_palette)">
                    <rect 
                        v-bind:x="posPaintEnd.x * cellDefaultSize * zoom_rates[zoom_level] - 55" 
                        :y="posPaintEnd.y * cellDefaultSize * zoom_rates[zoom_level] - 30" 
                        width="50px" 
                        height="25px"
                        fill="rgba(255, 55, 0, 0.9"
                        stroke="rgba(255, 255, 255, 0.9)"
                        stroke-width="2px"></rect>
                    <text v-bind:x="posPaintEnd.x * cellDefaultSize * zoom_rates[zoom_level] - 30" 
                      :y="posPaintEnd.y * cellDefaultSize * zoom_rates[zoom_level] - 10"
                      class="svgtext" 
                      font-size="16px" 
                      text-anchor="middle" 
                      fill="rgba(255,255,255,1)">塗る
                    </text>
                  </g>
                  <!-- 土間床上端のラインとラベル -->
                  <g class="domayukajotan_line" v-if="domayukajotan_line_y !== 0 && domayukajotan_line_y <= svg_height">
                    <line 
                      x1="0" 
                      :y1="(domayukajotan_line_y)" 
                      :x2="svg_width"
                      :y2="(domayukajotan_line_y)"
                      stroke="rgba(100,149,237, 0.9)"
                      stroke-width="5px"
                      stroke-dasharray="10"
                      stroke-dashoffset="10"></line>
                      <g v-if="domayukajotan_line_y !== ground_line_y">
                        <rect 
                          x="0" 
                          :y="domayukajotan_line_y -10" 
                          width="80px" 
                          height="20px"
                          fill="rgba(100,149,237, 1)">
                        </rect>
                        <path
                          :transform="'translate(80,'+ (domayukajotan_line_y - 10) +')'"
                          d="M0 0 L15 10 L0 20 Z"
                          fill="rgba(100,149,237, 1)"></path>
                        <text
                          x="10px" 
                          :y="domayukajotan_line_y + 5" 
                          class="svgtext" 
                          font-size="12px" 
                          text-anchor="center" 
                          fill="rgba(255,255,255,1)">土間床上端
                        </text>
                      </g>
                  </g>
                  <!-- GLのラインとラベル -->
                  <g class="gl_line" v-if="ground_line_y !== 0 && ground_line_y <= svg_height">
                    <line 
                      x1="0" 
                      :y1="(ground_line_y)" 
                      :x2="svg_width"
                      :y2="(ground_line_y)"
                      stroke="rgba(64, 25, 25, 0.9)"
                      stroke-width="5px"
                      stroke-dasharray="10"></line>
                      <g v-if="domayukajotan_line_y !== ground_line_y">
                        <rect 
                          x="0" 
                          :y="ground_line_y - 10" 
                          width="40px" 
                          height="20px"
                          fill="rgba(64, 25, 25, 1)">
                        </rect>
                        <path
                          :transform="'translate(40,'+ (ground_line_y - 10) +')'"
                          d="M0 0 L15 10 L0 20 Z"
                          fill="rgba(64, 25, 25, 1)"></path>
                        <text
                          x="10px" 
                          :y="ground_line_y + 5" 
                          class="svgtext" 
                          font-size="12px" 
                          text-anchor="center" 
                          fill="rgba(255,255,255,1)">GL
                        </text>
                      </g>
                      <!-- GLと土間床上面が同じ値の場合、ラベルの文言を変更 -->
                      <g v-if="domayukajotan_line_y == ground_line_y">
                        <rect 
                          x="0" 
                          :y="ground_line_y - 10" 
                          width="100px" 
                          height="20px"
                          fill="rgba(64, 25, 25, 1)">
                        </rect>
                        <path
                          :transform="'translate(100,'+ (ground_line_y - 10) +')'"
                          d="M0 0 L15 10 L0 20 Z"
                          fill="rgba(64, 25, 25, 1)"></path>
                        <text
                          x="10px" 
                          :y="ground_line_y + 5" 
                          class="svgtext" 
                          font-size="12px" 
                          text-anchor="center" 
                          fill="rgba(255,255,255,1)">GL・土間床上端
                        </text>
                      </g>
                  </g>

                  <!-- 基礎の心のラインとラベル -->
                  <g class="kabeshin_line" v-if="kabeshin_line_x !== 0 && kabeshin_line_x <= svg_height">
                    <line 
                      :x1="(kabeshin_line_x)" 
                      y1="0" 
                      :x2="(kabeshin_line_x)"
                      :y2="svg_width"
                      stroke="rgba(80, 80, 80, 1)"
                      stroke-width="5px"
                      stroke-dasharray="15 5"></line>
                    <rect 
                      :x="kabeshin_line_x - 29" 
                      y="0" 
                      width="58px" 
                      height="20px"
                      fill="rgba(80, 80, 80, 1)">
                    </rect>
                    <path
                      :transform="'translate('+ (kabeshin_line_x) +', 20)'"
                      d="M-5 0 L0 7 L5 0 Z"
                      fill="rgba(80, 80, 80, 1)"></path>
                    <text
                      :x="kabeshin_line_x - 23" 
                      y="14px" 
                      class="svgtext" 
                      font-size="12px" 
                      text-anchor="center" 
                      fill="rgba(255,255,255,1)">基礎の心
                    </text>
                  </g>
              </svg>
          </td>
				</tr>
			</tbody>
		</table>
</div>
</template>

<script>
export default {
  name: 'GridCanvas',
  data(){
    return {
      mode: 'normal',
      pos:{x: 0, y:0},
      posDragStart: {x: 0, y: 0}, 
      posDragEnd: {x: 0, y: 0},
      posPaintEnd : {x: 0, y: 0},
      posPaintStart: {x: 0, y: 0},
      selected_area_line_color: 'rgba(255, 55, 55, 0.4)',
    }
  },
  components: {
  },
  computed :{
    cell_count_x() {
      return this.griddata[0].row.length;
    },
    cell_count_y() {
      return this.griddata.length;
    },
    svg_width() {
      //セルの幅の総和をもとめる
      let svg_width = this.cellWidthArray.reduce(function (accumulator, currentValue) {
          return accumulator + currentValue;
      });
      return svg_width;
    },
    svg_height() {
      //セルの高さの総和をもとめる
      let svg_height = this.cellHeightArray.reduce(function (accumulator, currentValue) {
          return accumulator + currentValue;
      });
      return svg_height;
    },
    select_end_x(){
      return Math.abs((this.posPaintStart.x - this.posPaintEnd .x) * this.cellDefaultSize);
    },
    select_end_y(){
      return Math.abs((this.posPaintStart.y - this.posPaintEnd .y) * this.cellDefaultSize);
    },
    ground_line_y(){
      return this.cellYArray[this.ground_line_axis.y] * this.zoom_rates[this.zoom_level];
    },
    domayukajotan_line_y(){
      return this.cellYArray[this.domayukajotan_line_axis.y] * this.zoom_rates[this.zoom_level];
    },
    kabeshin_line_x(){
      return this.cellXArray[this.kabeshin_line_axis.x] * this.zoom_rates[this.zoom_level];
    },
    cellWidthArray(){
      //見た目幅ビューか均等幅ビューかに応じてセルの「幅」の数列を返す
      if(this.is_mitame_view){
        return this.widthList
      }else{
        return this.cellWidthList
      }
    },
    makeMitameCellXAxis(){
      //見た目幅ビューにおけるsvgのセルのx座標の数列をつくる
      const width_array =[0]
      for (let i = 0; i < this.widthList.length; i++){ 
          let calc_width = this.widthList[i] + width_array[width_array.length-1]
        
        width_array.push(calc_width)
      }
      return width_array
    },
    makeKintoCellXAxis(){
      //均等幅ビューにおけるsvgのセルのx座標の数列をつくる
      const width_array =[0]
      for (let i = 1; i < this.cellWidthList.length; i++){ 
          let calc_width = this.cellWidthList[i] + width_array[i-1]
        
        width_array.push(calc_width)
      }
      return width_array
    },
    cellXArray(){
      //見た目幅ビューか均等幅ビューかに応じてセルの「x座標」の数列を返す
      if(this.is_mitame_view){
        return this.makeMitameCellXAxis
      }else{
        return this.makeKintoCellXAxis
      }
    },
    cellHeightArray(){
      //見た目幅ビューか均等幅ビューかに応じてセルの「高さ」の数列を返す
      if(this.is_mitame_view){
        return this.heightList
      }else{
        return this.cellHeightList
      }
    },
    makeMitameCellYAxis(){
      //見た目幅ビューにおけるsvgのセルのｙ座標の数列をつくる
      const height_array =[0]
      for (let i = 0; i < this.heightList.length; i++){ 
          let calc_height = this.heightList[i] + height_array[height_array.length-1]
        
        height_array.push(calc_height)
      }
      return height_array
    },
    makeKintoCellYAxis(){
      //均等幅ビューにおけるsvgのセルのy座標の数列をつくる
      const height_array =[0]
      for (let i = 1; i < this.cellHeightList.length; i++){ 
          let calc_height = this.cellHeightList[i] + height_array[i-1]
        
        height_array.push(calc_height)
      }
      return height_array
    },
    cellYArray(){
      //見た目幅ビューか均等幅ビューかに応じてセルの「y座標」の数列を返す
      if(this.is_mitame_view){
        return this.makeMitameCellYAxis
      }else{
        return this.makeKintoCellYAxis
      }
    },
  },
  props: ['widthList', 'heightList', 'cellWidthList', 'cellHeightList', 'viewbox', 'griddata', 'current_palette','zoom_level','zoom_rates','ground_line_axis','domayukajotan_line_axis','kabeshin_line_axis','is_mitame_view','cellDefaultSize'],
  methods: {
    //座標を取得してApp.vueに送る + ハイライトする
    mouseover(e){
      this.pos.x= this.getpos(e).x
      this.pos.y= this.getpos(e).y
      this.$emit('move_cursor', this.pos ,e)

      if(0 < this.pos.x && 0 < this.pos.y ){
        //Xルーラーをハイライト
        for(let i=0; i<this.widthList.length; i++){
          document.getElementsByClassName('x_ruler_g').item(i).classList.remove('highlighted');
        }
        let x_highlight = document.getElementsByClassName('x_ruler_g').item(this.pos.x);
        x_highlight.classList.add('highlighted');

        //Yルーラーをハイライト
        for(let i=0; i<this.heightList.length; i++){
          document.getElementsByClassName('y_ruler_g').item(i).classList.remove('highlighted');
        }
        let y_highlight = document.getElementsByClassName('y_ruler_g').item(this.pos.y);
        y_highlight.classList.add('highlighted');
      }
    },
    //カーソルがセルテーブルの外に出た時、座標を-1とする
    axisOnMouseleave(e){
      this.pos.x= -1
      this.pos.y= -1
      this.$emit('move_cursor', this.pos ,e)

      if(this.mode == 'selecting'){
        this.axisOnMouseup(e)
      }
    },
    //座標を取得する
    getpos(e) {
      if(!this.is_mitame_view){
        //均等幅ビューの場合
        return {
          x: Math.ceil(e.offsetX / (this.cellDefaultSize * this.zoom_rates[this.zoom_level])) - 1,
          y: Math.ceil(e.offsetY / (this.cellDefaultSize * this.zoom_rates[this.zoom_level])) - 1
        }
      }else{
        //見た目幅ビューの場合
        return {
          x: 0,
          y: 0
        }
      }
      
    },
    //マウスダウンで座標を取得
    axisOnMousedown(e){
      this.selected_area_line_color = 'rgba(255, 55, 55, 0.4)'//選択範囲の境界線色リセット
      if (this.mode == 'normal' || this.mode == 'ruler_selecting'){
        this.posDragStart = this.getpos(e)
        this.posDragEnd = this.getpos(e)
        this.posPaintEnd .x = Math.max(this.posDragStart.x,this.posDragEnd.x);
        this.posPaintEnd .y = Math.max(this.posDragStart.y,this.posDragEnd.y);
        this.posPaintStart.x = Math.min(this.posDragStart.x,this.posDragEnd.x);
        this.posPaintStart.y = Math.min(this.posDragStart.y,this.posDragEnd.y);
        this.mode = 'down'
      }else if(this.mode == 'selected'){
        this.posDragStart = this.getpos(e)
        this.posDragEnd = this.getpos(e)
        this.posPaintEnd .x = Math.max(this.posDragStart.x,this.posDragEnd.x);
        this.posPaintEnd .y = Math.max(this.posDragStart.y,this.posDragEnd.y);
        this.posPaintStart.x = Math.min(this.posDragStart.x,this.posDragEnd.x);
        this.posPaintStart.y = Math.min(this.posDragStart.y,this.posDragEnd.y);
        this.mode = 'down'
      }
    },
    //マウスアップで座標を取得
    axisOnMouseup(e){
      if (this.mode == 'down'){
        if(!this.is_mitame_view && this.mode !== 'selected'){
          this.paintIntenal(this.posDragStart.x, this.posDragStart.y, this.current_palette)
        }
        this.mode = 'normal'
      }
      else if(this.mode == 'selecting') {
        //範囲確定
        if(this.posDragStart.x == this.posDragEnd.x && this.posDragStart.y == this.posDragEnd.y){
          this.mode = 'normal'
          if(!this.is_mitame_view && this.mode !== 'selected'){
            this.paintIntenal(this.posDragStart.x, this.posDragStart.y, this.current_palette)
          }
        }else{
          this.posDragEnd = this.getpos(e)
          this.mode = 'selected'
        }
      }
    },
    //マウス移動中
    axisOnMousemove(e){
      if (this.mode == 'down'){
        this.mode = 'selecting'
      }
      else if (this.mode == 'selecting'){
        this.posDragEnd = this.getpos(e)
        this.posPaintEnd .x = Math.max(this.posDragStart.x,this.posDragEnd.x + 1);
        this.posPaintEnd .y = Math.max(this.posDragStart.y,this.posDragEnd.y + 1);
        this.posPaintStart.x = Math.min(this.posDragStart.x,this.posDragEnd.x);
        this.posPaintStart.y = Math.min(this.posDragStart.y,this.posDragEnd.y);
      }
    },
    // セル情報の変更有無
    changedFlag(){
      this.$emit('result-reset');
    },
    // 塗り関係
    paint(e){
      if (!this.is_mitame_view && this.mode == 'normal'){
        let pos = this.getpos(e);
        this.paintIntenal(pos.x, pos.y, this.current_palette);
      }
    },
    paintIntenal(x, y, current_palette){
      if (!this.is_mitame_view){
        this.griddata[y].row.splice(x, 1, current_palette);
        this.changedFlag();
      }
    },
    paintArea(x_start, y_start, x_end, y_end, palette){
      for(let y = y_start; y <= y_end; y++){
          for(let x = x_start; x <= x_end; x++){
              this.paintIntenal(x,y,palette);
          }
      }
    },
    // 選択範囲を塗る
    areaPaint(current_parette){
      //ルーラー選択中以外の時、範囲を塗る
      if(this.mode != 'ruler_selecting'){
        this.paintArea(this.posPaintStart.x, this.posPaintStart.y, this.posPaintEnd .x -1 , this.posPaintEnd .y - 1, current_parette);
        this.posPaintEnd .x = 0;
        this.posPaintEnd .y = 0;
        this.posPaintStart.x = 0;
        this.posPaintStart.y = 0;
      }
    },
    // 右クリックメニューを隠す
    hideMenu() {
      this.$emit('hidemenu');//#Appで右クリックメニューがあれば閉じる
    },
    hideContextMenu() {
      this.$refs.rulerX.hideContextMenu();
      this.$refs.rulerY.hideContextMenu();
    },
    // 列・行の追加と削除
    addColumn(start_x) {
      this.$emit('add-column',start_x)
    },
    removeColumn(start_x) {
      this.$emit('remove-column',start_x)
    },
    addRow(start_y) {
      this.$emit('add-row',start_y)
    },
    removeRow(start_y) {
      this.$emit('remove-row',start_y)
    },
    /* ルーラーを選択中に座標ハイライト */
    xrulerHighlight(columnIndex){
      this.mode = 'ruler_selecting'
      this.selected_area_line_color = 'rgba(255, 255, 255, 0.8)'
      this.posPaintEnd .x = columnIndex + 1;
      this.posPaintEnd .y = this.heightList.length;
      this.posPaintStart.x = columnIndex;
      this.posPaintStart.y = 0;
    },
    yrulerHighlight(rowIndex){
      this.mode = 'ruler_selecting'
      this.selected_area_line_color = 'rgba(255, 255, 255, 0.8)'
      this.posPaintEnd .x = this.widthList.length;
      this.posPaintEnd .y = rowIndex + 1;
      this.posPaintStart.x = 0;
      this.posPaintStart.y = rowIndex;
    },
    //GLの座標を指定する。
    makeGlAxis(rowIndex){
        this.$emit('make-gl-axis', rowIndex);
    },
    //土間床上端の座標を指定する。
    makeDomayukajotanAxis(rowIndex){
        this.$emit('make-domayukajotan-axis', rowIndex);
    },
    //基礎の心のx座標を指定する。
    makeKabeshinAxis(columnIndex){
        this.$emit('make-kabeshin-axis', columnIndex);
    },

  }
}


</script>

